import Vue from 'vue';
import security from "@/core/plugins/security";
import {Verifiable} from "@/core/types/vuetify";
import tip from "@/configure/tip";
import service from '@/core/service';

export default Vue.extend({
    async created() {
        const isAuth = await security.auth("");
        if (isAuth) await this.$router.push({path: "/"});
    },
    data: () => ({
        account: "",
        password: "",
        valid: true,
        loading: false,
        sending: false,
        message: {
            show: false,
            color: "success",
            text: ""
        },
        timeout: 2000,
        style: {
            show: false,
            autofocus: true
        },
        rule: {
            requiredAccount: (value: string) => !!value || tip.login.rule.requiredAccount,
            requiredPassword: (value: string) => !!value || tip.login.rule.requiredPassword
        },
    }),
    mounted() {
        if (process.env.NODE_ENV !== 'production') {
            this.account = localStorage.getItem("account") ?? "";
        }
    },
    methods: {
        async submit() {
            if (this.form.validate()) {
                this.loading = true;
                this.message.show = false;
                try {
                    await security.login(this.account, this.password);
                    await this.$router.push({path: "/"});
                    if (process.env.NODE_ENV !== 'production')
                        localStorage.setItem("account", this.account);
                } catch (reason) {
                    this.alert("error", tip.login.submit.error);
                    this.loading = false;
                }
            }
        },
        async send() {
            if (this.form.validate()) {
                this.sending = true;
                try {
                    await service.security.send(this.account);
                    this.alert("success", tip.login.send.success);
                    // @ts-ignore
                    this.$refs.password.focus();
                } catch (reason) {
                    this.alert("error", tip.login.send.error);
                }
                this.sending = false;
            }
        },
        alert(color: string, text: string) {
            this.message.color = color;
            this.message.text = text;
            this.message.show = true;
        }
    },
    computed: {
        form(): Verifiable {
            return this.$refs.form as Verifiable
        }
    }
});
